<template>
  <div class="container">
    <div class="banner">
      <img :src="data.banner" alt="">
    </div>
    <div class="box">
      <div class="left">
        <div class="titleNav cursor">首页>{{ data.type == 1 ? '企业动态' : '企业动态' }}>{{ data.title }}！</div>
        <div class="title">{{ data.title }}</div>
        <div class="time"><span class="icon iconjinlingyingcaiwangtubiao74"></span> {{ data.time }}</div>

        <div class="content" v-html="data.text">
        </div>

        <div class="label">
          <div class="lbox">
            <div class="t">标签</div>
            <div class="a cursor">{{ data.label }}</div>
          </div>
        </div>

        <div class="pagination">
          <div class="cursor">
            <router-link :to="`/news?type=${ data.list[1].type}&id=${ data.list[1].id}`">上一资讯：{{
                data.list[1].title
              }}
            </router-link>
          </div>
          <div class="cursor">
            <router-link :to="`/news?type=${ data.list[0].type}&id=${ data.list[0].id}`">下一资讯：{{
                data.list[0].title
              }}
            </router-link>
          </div>
        </div>

      </div>
      <div class="right">
        <div class="title">新闻推荐</div>
        <div class="boxList">

          <router-link :to="`/news?type=${v.type}&id=${v.id}`" v-for="(v,i) in data.list" :key="i">
            <div class="item cursor">
              <div class="l">
                <img :src="v.img" alt="">
              </div>
              <div class="r">{{ v.text | filterText }}</div>
            </div>
          </router-link>

        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {Data, scrolTop} from "@/utils/ decorator";

export default {
  name: "news",
  data() {
    return {
      data: {},
    }
  },
  @scrolTop
      @Data('news')
  created() {


    let {type, id} = this.$route.query;

    if (!id) id = this.d.find(r => r.type == type).id;

    this.data = this.d.find(r => r.id == id);

    /**
     * 比较函数
     * @param x
     * @param y
     * @returns {number}
     */
    const compare = function (x, y) {
      if (type < y.type) {
        return -1;
      } else if (x.type > type) {
        return 1;
      } else {
        return 0;
      }
    };
    this.data.list = this.d.filter(r => r.id != id).sort(compare);
  }
}
</script>

<style lang="less" scoped>

.banner {
  height: 500px;

  img {
    width: 100%;
    height: 100%;
  }
}

.box {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 112px;
  padding-top: 58px;


  .left {
    width: 60%;

    .titleNav {
      font-family: Microsoft YaHei, serif;
      width: 100%;
      height: 35px;
      font-size: 14px;
      font-weight: 400;
      color: #24439C;
      line-height: 35px;
      padding-left: 10px;
      margin-left: 5px;
      background: #f8f8f8;
      border-radius: 5px;

    }

    .title {
      font-size: 48px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin: 50px 0 30px;
      text-align: left;
    }

    .time {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;

      span {
        font-size: 14px;
        position: relative;
        top: -2px;
      }
    }

    /deep/ .content {
      padding-left: 10px;
      width: 100%;
      //height: 700px;
      padding-bottom: 44px;
      border-bottom: 1px solid #F5F5F5;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      color: #666666;
      text-indent: 30px;

      overflow: hidden;

      p {
        margin: 30px 0 0;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    .label {
      padding-left: 10px;
      width: 100%;
      margin: 32px 0 31px;
      padding-bottom: 32px;
      border-bottom: 1px solid #F5F5F5;


      .lbox {
        padding-left: 34px;
        padding-top: 16px;
        height: 95px;
        width: 100%;
        background: #BFBFBF;
        border-radius: 5px;

        .t {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin-bottom: 13px;

        }

        .a {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 300;
          color: #666666;
        }
      }

    }

    .pagination {
      div {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        margin-top: 38px;

        a {
          color: #3450A0;

        }
      }
    }

  }

  .right {
    width: 35%;
    padding-top: 70px;

    .title {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      border-bottom: 1px solid #F5F5F5;
      padding-bottom: 14px;
    }

    a {
      display: block;
      height: 130px;
    }


    .item {
      margin-top: 20px;
      padding-bottom: 18px;
      border-bottom: 1px solid #F5F5F5;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .l {
        width: 40%;
        height: 118px;

        img {
          width: 100%;
          height: 100%
        }
      }

      .r {
        width: 55%;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        //height: 57px;
        overflow: hidden;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;

      }

    }

  }

}

</style>







